<template>
	<form class="form-signup" novalidate @submit.prevent="onSubmit">
		<h3 class="form__title">Join our club</h3>

		<div class="form__inner">
			<label class="input-wrp">
				<input
					class="textfield"
					:class="{'__invalid': sErrorField === 'name'}"
					type="text"
					name="name"
					placeholder="First Name "
				/>

				<span v-if="sErrorField === 'name'" class="textfield-error">{{ sErrorMessage }}</span>
			</label>

			<label class="input-wrp">
				<input
					class="textfield"
					:class="{'__invalid': sErrorField === 'email'}"
					type="text"
					name="email"
					placeholder="E-mail"
					inputmode="email"
					x-inputmode="email"
				/>

				<span v-if="sErrorField === 'email'" class="textfield-error">{{ sErrorMessage }}</span>
			</label>

			<label
				class="checkfield"
				:class="{'__invalid': sErrorField === 'agree'}"
			>
				<input
					type="checkbox"
					name="agree"
				/>
				<i></i>
				<span>I agree with License agreement</span>
				<span v-if="sErrorField === 'agree'" class="textfield-error">{{ sErrorMessage }}</span>
			</label>

			<button type="submit" class="custom-btn wide form__submit">Join us</button>
		</div>
	</form>
</template>

<script>
import axios from 'axios';

export default {
	name: 'form-signup',

	data: function ()
	{
		return {
			sUserIP: window.userIP || '',

			sErrorField: '',
			sErrorMessage: ''
		}
	},

	methods: {
		onSubmit: async function ()
		{
			if (this.__loading) return;


			var nForm = this.$el,

				nName = nForm.elements.name,
				sName = nName.value,

				nEmail = nForm.elements.email,
				sEmail = nEmail.value,

				sAgree = nForm.elements.agree.value,

				rEmail = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i

			this.sErrorField = '';
			this.sErrorMessage = '';

			if (!sName)
			{
				this.sErrorField = 'name';
				this.sErrorMessage = 'This field is required';

				nName.focus();

				return;
			}

			if (sName.length < 3)
			{
				this.sErrorField = 'name';
				this.sErrorMessage = 'Please enter at least 3 characters';

				nName.focus();

				return;
			}

			if (!sEmail)
			{
				this.sErrorField = 'email';
				this.sErrorMessage = 'This field is required';

				nEmail.focus();

				return;
			}

			var isEmailValid = rEmail.test(sEmail);

			if (!isEmailValid)
			{
				this.sErrorField = 'email';
				this.sErrorMessage = 'Please, enter a valid email';

				nEmail.focus();

				return;
			}

			sEmail = encodeURIComponent(sEmail.toLowerCase());

			if (!nForm.elements.agree.checked)
			{
				this.sErrorField = 'agree';
				this.sErrorMessage = 'This field is required';

				return;
			}

			var sFormData = 'name=' + sName + '&email=' + sEmail +'&sign_agree=' + sAgree;


			this.__loading = true;


			if (!this.sUserIP)
			{
				window.userIP = this.sUserIP = await this.getIP();
			}

			var oSearchParams = new URL(document.location).searchParams;

			sFormData += '&ip=' + this.sUserIP + '&pid=' + oSearchParams.get('pid') + '&pid_sid=' + oSearchParams.get('sid');

			try
			{
				var oResp = await axios.post('/api/?action=registration', sFormData);

				this.$root.isPreloadGeneral = true;

				this.__loading = false;

				if (oResp.data.error && oResp.data.error !== '')
				{
					var sErrorMessage = 'E-mail is already registered';

					this.$root.isPreloadGeneral = false;

					if (oResp.data.error === sErrorMessage)
					{
						this.sErrorField = 'email';
						this.sErrorMessage = sErrorMessage;

						nEmail.focus();

						return;
					}

					alert(oResp.data.error);
				}
				else if (oResp.data.loc)
				{
					setTimeout(function()
					{
						document.location = oResp.data.loc;
					}, 500);
				}
			}
			catch (e)
			{
				this.__loading = false;
				this.$root.isPreloadGeneral = false;
				console.error(e);
			}
		},

		getIP: async function ()
		{
			try
			{
				var oResp = await axios('https://api.ipify.org?format=json');

				return oResp.data.ip;
			}
			catch (e)
			{
				console.error(e);

				return e;
			}
		}
	}
}
</script>

<style lang="scss">
.form-signup
{
	text-align: center;

	.textfield
	{
		padding-left: 54px;
		background-repeat: no-repeat;
		background-position: 19px center;

		&[name='name']
		{
			background-image: url('../assets/icon-user.svg');
		}

		&[name='email']
		{
			background-image: url('../assets/icon-mail.svg');
		}
	}
}

.form
{
	&__title
	{
		margin-bottom: 35px;
		line-height: 1;
		font-size: 3rem;
		font-weight: 400;
		color: #FF5510;
	}

	&__submit
	{
		margin-top: 25px;
	}

	@media (min-width: 576px)
	{
		&__title
		{
			margin-bottom: 50px;
			font-size: 5rem;
		}
	}
}
</style>
